// source: src/simlin-engine/src/project_io.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var proto = {};

goog.exportSymbol('project_io.Dimension', null, proto);
goog.exportSymbol('project_io.Dimension.DimensionCase', null, proto);
goog.exportSymbol('project_io.Dimension.DimensionElements', null, proto);
goog.exportSymbol('project_io.Dimension.DimensionSize', null, proto);
goog.exportSymbol('project_io.Dt', null, proto);
goog.exportSymbol('project_io.GraphicalFunction', null, proto);
goog.exportSymbol('project_io.GraphicalFunction.Kind', null, proto);
goog.exportSymbol('project_io.GraphicalFunction.Scale', null, proto);
goog.exportSymbol('project_io.Model', null, proto);
goog.exportSymbol('project_io.Project', null, proto);
goog.exportSymbol('project_io.Rect', null, proto);
goog.exportSymbol('project_io.SimMethod', null, proto);
goog.exportSymbol('project_io.SimSpecs', null, proto);
goog.exportSymbol('project_io.Source', null, proto);
goog.exportSymbol('project_io.Source.Extension', null, proto);
goog.exportSymbol('project_io.Unit', null, proto);
goog.exportSymbol('project_io.Variable', null, proto);
goog.exportSymbol('project_io.Variable.ApplyToAllEquation', null, proto);
goog.exportSymbol('project_io.Variable.ArrayedEquation', null, proto);
goog.exportSymbol('project_io.Variable.ArrayedEquation.Element', null, proto);
goog.exportSymbol('project_io.Variable.Aux', null, proto);
goog.exportSymbol('project_io.Variable.Equation', null, proto);
goog.exportSymbol('project_io.Variable.Equation.EquationCase', null, proto);
goog.exportSymbol('project_io.Variable.Flow', null, proto);
goog.exportSymbol('project_io.Variable.Module', null, proto);
goog.exportSymbol('project_io.Variable.Module.Reference', null, proto);
goog.exportSymbol('project_io.Variable.ScalarEquation', null, proto);
goog.exportSymbol('project_io.Variable.Stock', null, proto);
goog.exportSymbol('project_io.Variable.VCase', null, proto);
goog.exportSymbol('project_io.Variable.Visibility', null, proto);
goog.exportSymbol('project_io.View', null, proto);
goog.exportSymbol('project_io.View.ViewType', null, proto);
goog.exportSymbol('project_io.ViewElement', null, proto);
goog.exportSymbol('project_io.ViewElement.Alias', null, proto);
goog.exportSymbol('project_io.ViewElement.Aux', null, proto);
goog.exportSymbol('project_io.ViewElement.Cloud', null, proto);
goog.exportSymbol('project_io.ViewElement.ElementCase', null, proto);
goog.exportSymbol('project_io.ViewElement.Flow', null, proto);
goog.exportSymbol('project_io.ViewElement.FlowPoint', null, proto);
goog.exportSymbol('project_io.ViewElement.LabelSide', null, proto);
goog.exportSymbol('project_io.ViewElement.Link', null, proto);
goog.exportSymbol('project_io.ViewElement.Link.LinkPoints', null, proto);
goog.exportSymbol('project_io.ViewElement.Link.ShapeCase', null, proto);
goog.exportSymbol('project_io.ViewElement.Module', null, proto);
goog.exportSymbol('project_io.ViewElement.Stock', null, proto);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.GraphicalFunction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.GraphicalFunction.repeatedFields_, null);
};
goog.inherits(proto.project_io.GraphicalFunction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.GraphicalFunction.displayName = 'proto.project_io.GraphicalFunction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.GraphicalFunction.Scale = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.GraphicalFunction.Scale, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.GraphicalFunction.Scale.displayName = 'proto.project_io.GraphicalFunction.Scale';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.project_io.Variable.oneofGroups_);
};
goog.inherits(proto.project_io.Variable, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.displayName = 'proto.project_io.Variable';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.ScalarEquation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Variable.ScalarEquation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.ScalarEquation.displayName = 'proto.project_io.Variable.ScalarEquation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.ApplyToAllEquation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Variable.ApplyToAllEquation.repeatedFields_, null);
};
goog.inherits(proto.project_io.Variable.ApplyToAllEquation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.ApplyToAllEquation.displayName = 'proto.project_io.Variable.ApplyToAllEquation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.ArrayedEquation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Variable.ArrayedEquation.repeatedFields_, null);
};
goog.inherits(proto.project_io.Variable.ArrayedEquation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.ArrayedEquation.displayName = 'proto.project_io.Variable.ArrayedEquation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.ArrayedEquation.Element = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Variable.ArrayedEquation.Element, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.ArrayedEquation.Element.displayName = 'proto.project_io.Variable.ArrayedEquation.Element';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Equation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.project_io.Variable.Equation.oneofGroups_);
};
goog.inherits(proto.project_io.Variable.Equation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Equation.displayName = 'proto.project_io.Variable.Equation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Stock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Variable.Stock.repeatedFields_, null);
};
goog.inherits(proto.project_io.Variable.Stock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Stock.displayName = 'proto.project_io.Variable.Stock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Flow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Variable.Flow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Flow.displayName = 'proto.project_io.Variable.Flow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Aux = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Variable.Aux, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Aux.displayName = 'proto.project_io.Variable.Aux';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Module = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Variable.Module.repeatedFields_, null);
};
goog.inherits(proto.project_io.Variable.Module, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Module.displayName = 'proto.project_io.Variable.Module';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Variable.Module.Reference = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Variable.Module.Reference, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Variable.Module.Reference.displayName = 'proto.project_io.Variable.Module.Reference';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.project_io.ViewElement.oneofGroups_);
};
goog.inherits(proto.project_io.ViewElement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.displayName = 'proto.project_io.ViewElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Aux = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.Aux, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Aux.displayName = 'proto.project_io.ViewElement.Aux';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Stock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.Stock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Stock.displayName = 'proto.project_io.ViewElement.Stock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.FlowPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.FlowPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.FlowPoint.displayName = 'proto.project_io.ViewElement.FlowPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Flow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.ViewElement.Flow.repeatedFields_, null);
};
goog.inherits(proto.project_io.ViewElement.Flow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Flow.displayName = 'proto.project_io.ViewElement.Flow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.project_io.ViewElement.Link.oneofGroups_);
};
goog.inherits(proto.project_io.ViewElement.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Link.displayName = 'proto.project_io.ViewElement.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Link.LinkPoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.ViewElement.Link.LinkPoints.repeatedFields_, null);
};
goog.inherits(proto.project_io.ViewElement.Link.LinkPoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Link.LinkPoints.displayName = 'proto.project_io.ViewElement.Link.LinkPoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Module = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.Module, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Module.displayName = 'proto.project_io.ViewElement.Module';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Alias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.Alias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Alias.displayName = 'proto.project_io.ViewElement.Alias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.ViewElement.Cloud = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.ViewElement.Cloud, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.ViewElement.Cloud.displayName = 'proto.project_io.ViewElement.Cloud';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Rect = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Rect, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Rect.displayName = 'proto.project_io.Rect';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.View = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.View.repeatedFields_, null);
};
goog.inherits(proto.project_io.View, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.View.displayName = 'proto.project_io.View';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Model.repeatedFields_, null);
};
goog.inherits(proto.project_io.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Model.displayName = 'proto.project_io.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Dt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Dt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Dt.displayName = 'proto.project_io.Dt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.SimSpecs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.SimSpecs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.SimSpecs.displayName = 'proto.project_io.SimSpecs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Dimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Dimension.repeatedFields_, proto.project_io.Dimension.oneofGroups_);
};
goog.inherits(proto.project_io.Dimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Dimension.displayName = 'proto.project_io.Dimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Dimension.DimensionElements = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Dimension.DimensionElements.repeatedFields_, null);
};
goog.inherits(proto.project_io.Dimension.DimensionElements, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Dimension.DimensionElements.displayName = 'proto.project_io.Dimension.DimensionElements';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Dimension.DimensionSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Dimension.DimensionSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Dimension.DimensionSize.displayName = 'proto.project_io.Dimension.DimensionSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Unit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Unit.repeatedFields_, null);
};
goog.inherits(proto.project_io.Unit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Unit.displayName = 'proto.project_io.Unit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.project_io.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Source.displayName = 'proto.project_io.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.project_io.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.project_io.Project.repeatedFields_, null);
};
goog.inherits(proto.project_io.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.project_io.Project.displayName = 'proto.project_io.Project';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.GraphicalFunction.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.GraphicalFunction.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.GraphicalFunction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.GraphicalFunction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.GraphicalFunction.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    xPointsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 2)) == null ? undefined : f,
    yPointsList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    xScale: (f = msg.getXScale()) && proto.project_io.GraphicalFunction.Scale.toObject(includeInstance, f),
    yScale: (f = msg.getYScale()) && proto.project_io.GraphicalFunction.Scale.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.GraphicalFunction}
 */
proto.project_io.GraphicalFunction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.GraphicalFunction;
  return proto.project_io.GraphicalFunction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.GraphicalFunction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.GraphicalFunction}
 */
proto.project_io.GraphicalFunction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.project_io.GraphicalFunction.Kind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addXPoints(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addYPoints(values[i]);
      }
      break;
    case 4:
      var value = new proto.project_io.GraphicalFunction.Scale;
      reader.readMessage(value,proto.project_io.GraphicalFunction.Scale.deserializeBinaryFromReader);
      msg.setXScale(value);
      break;
    case 5:
      var value = new proto.project_io.GraphicalFunction.Scale;
      reader.readMessage(value,proto.project_io.GraphicalFunction.Scale.deserializeBinaryFromReader);
      msg.setYScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.GraphicalFunction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.GraphicalFunction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.GraphicalFunction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.GraphicalFunction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getXPointsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      2,
      f
    );
  }
  f = message.getYPointsList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getXScale();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.GraphicalFunction.Scale.serializeBinaryToWriter
    );
  }
  f = message.getYScale();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.project_io.GraphicalFunction.Scale.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.project_io.GraphicalFunction.Kind = {
  CONTINUOUS: 0,
  DISCRETE: 1,
  EXTRAPOLATE: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.GraphicalFunction.Scale.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.GraphicalFunction.Scale.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.GraphicalFunction.Scale} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.GraphicalFunction.Scale.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.GraphicalFunction.Scale}
 */
proto.project_io.GraphicalFunction.Scale.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.GraphicalFunction.Scale;
  return proto.project_io.GraphicalFunction.Scale.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.GraphicalFunction.Scale} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.GraphicalFunction.Scale}
 */
proto.project_io.GraphicalFunction.Scale.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.GraphicalFunction.Scale.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.GraphicalFunction.Scale.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.GraphicalFunction.Scale} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.GraphicalFunction.Scale.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double min = 1;
 * @return {number}
 */
proto.project_io.GraphicalFunction.Scale.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.GraphicalFunction.Scale} returns this
 */
proto.project_io.GraphicalFunction.Scale.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double max = 2;
 * @return {number}
 */
proto.project_io.GraphicalFunction.Scale.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.GraphicalFunction.Scale} returns this
 */
proto.project_io.GraphicalFunction.Scale.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Kind kind = 1;
 * @return {!proto.project_io.GraphicalFunction.Kind}
 */
proto.project_io.GraphicalFunction.prototype.getKind = function() {
  return /** @type {!proto.project_io.GraphicalFunction.Kind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.project_io.GraphicalFunction.Kind} value
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated double x_points = 2;
 * @return {!Array<number>}
 */
proto.project_io.GraphicalFunction.prototype.getXPointsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.setXPointsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.addXPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.clearXPointsList = function() {
  return this.setXPointsList([]);
};


/**
 * repeated double y_points = 3;
 * @return {!Array<number>}
 */
proto.project_io.GraphicalFunction.prototype.getYPointsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.setYPointsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.addYPoints = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.clearYPointsList = function() {
  return this.setYPointsList([]);
};


/**
 * optional Scale x_scale = 4;
 * @return {?proto.project_io.GraphicalFunction.Scale}
 */
proto.project_io.GraphicalFunction.prototype.getXScale = function() {
  return /** @type{?proto.project_io.GraphicalFunction.Scale} */ (
    jspb.Message.getWrapperField(this, proto.project_io.GraphicalFunction.Scale, 4));
};


/**
 * @param {?proto.project_io.GraphicalFunction.Scale|undefined} value
 * @return {!proto.project_io.GraphicalFunction} returns this
*/
proto.project_io.GraphicalFunction.prototype.setXScale = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.clearXScale = function() {
  return this.setXScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.GraphicalFunction.prototype.hasXScale = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Scale y_scale = 5;
 * @return {?proto.project_io.GraphicalFunction.Scale}
 */
proto.project_io.GraphicalFunction.prototype.getYScale = function() {
  return /** @type{?proto.project_io.GraphicalFunction.Scale} */ (
    jspb.Message.getWrapperField(this, proto.project_io.GraphicalFunction.Scale, 5));
};


/**
 * @param {?proto.project_io.GraphicalFunction.Scale|undefined} value
 * @return {!proto.project_io.GraphicalFunction} returns this
*/
proto.project_io.GraphicalFunction.prototype.setYScale = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.GraphicalFunction} returns this
 */
proto.project_io.GraphicalFunction.prototype.clearYScale = function() {
  return this.setYScale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.GraphicalFunction.prototype.hasYScale = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.project_io.Variable.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.project_io.Variable.VCase = {
  V_NOT_SET: 0,
  STOCK: 1,
  FLOW: 2,
  AUX: 3,
  MODULE: 4
};

/**
 * @return {proto.project_io.Variable.VCase}
 */
proto.project_io.Variable.prototype.getVCase = function() {
  return /** @type {proto.project_io.Variable.VCase} */(jspb.Message.computeOneofCase(this, proto.project_io.Variable.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.toObject = function(includeInstance, msg) {
  var f, obj = {
    stock: (f = msg.getStock()) && proto.project_io.Variable.Stock.toObject(includeInstance, f),
    flow: (f = msg.getFlow()) && proto.project_io.Variable.Flow.toObject(includeInstance, f),
    aux: (f = msg.getAux()) && proto.project_io.Variable.Aux.toObject(includeInstance, f),
    module: (f = msg.getModule()) && proto.project_io.Variable.Module.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable}
 */
proto.project_io.Variable.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable;
  return proto.project_io.Variable.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable}
 */
proto.project_io.Variable.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.project_io.Variable.Stock;
      reader.readMessage(value,proto.project_io.Variable.Stock.deserializeBinaryFromReader);
      msg.setStock(value);
      break;
    case 2:
      var value = new proto.project_io.Variable.Flow;
      reader.readMessage(value,proto.project_io.Variable.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    case 3:
      var value = new proto.project_io.Variable.Aux;
      reader.readMessage(value,proto.project_io.Variable.Aux.deserializeBinaryFromReader);
      msg.setAux(value);
      break;
    case 4:
      var value = new proto.project_io.Variable.Module;
      reader.readMessage(value,proto.project_io.Variable.Module.deserializeBinaryFromReader);
      msg.setModule(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStock();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.project_io.Variable.Stock.serializeBinaryToWriter
    );
  }
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.project_io.Variable.Flow.serializeBinaryToWriter
    );
  }
  f = message.getAux();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.project_io.Variable.Aux.serializeBinaryToWriter
    );
  }
  f = message.getModule();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.Variable.Module.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.project_io.Variable.Visibility = {
  PRIVATE: 0,
  PUBLIC: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.ScalarEquation.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.ScalarEquation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.ScalarEquation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ScalarEquation.toObject = function(includeInstance, msg) {
  var f, obj = {
    equation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    initialEquation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.ScalarEquation}
 */
proto.project_io.Variable.ScalarEquation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.ScalarEquation;
  return proto.project_io.Variable.ScalarEquation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.ScalarEquation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.ScalarEquation}
 */
proto.project_io.Variable.ScalarEquation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialEquation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.ScalarEquation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.ScalarEquation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.ScalarEquation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ScalarEquation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEquation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string equation = 1;
 * @return {string}
 */
proto.project_io.Variable.ScalarEquation.prototype.getEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ScalarEquation} returns this
 */
proto.project_io.Variable.ScalarEquation.prototype.setEquation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string initial_equation = 2;
 * @return {string}
 */
proto.project_io.Variable.ScalarEquation.prototype.getInitialEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ScalarEquation} returns this
 */
proto.project_io.Variable.ScalarEquation.prototype.setInitialEquation = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.project_io.Variable.ScalarEquation} returns this
 */
proto.project_io.Variable.ScalarEquation.prototype.clearInitialEquation = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.ScalarEquation.prototype.hasInitialEquation = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Variable.ApplyToAllEquation.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.ApplyToAllEquation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.ApplyToAllEquation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ApplyToAllEquation.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimensionNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    equation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialEquation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.ApplyToAllEquation}
 */
proto.project_io.Variable.ApplyToAllEquation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.ApplyToAllEquation;
  return proto.project_io.Variable.ApplyToAllEquation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.ApplyToAllEquation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.ApplyToAllEquation}
 */
proto.project_io.Variable.ApplyToAllEquation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDimensionNames(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialEquation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.ApplyToAllEquation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.ApplyToAllEquation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ApplyToAllEquation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimensionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated string dimension_names = 1;
 * @return {!Array<string>}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.getDimensionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.setDimensionNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.addDimensionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.clearDimensionNamesList = function() {
  return this.setDimensionNamesList([]);
};


/**
 * optional string equation = 2;
 * @return {string}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.getEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.setEquation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_equation = 3;
 * @return {string}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.getInitialEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.setInitialEquation = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.project_io.Variable.ApplyToAllEquation} returns this
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.clearInitialEquation = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.ApplyToAllEquation.prototype.hasInitialEquation = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Variable.ArrayedEquation.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.ArrayedEquation.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.ArrayedEquation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.ArrayedEquation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ArrayedEquation.toObject = function(includeInstance, msg) {
  var f, obj = {
    dimensionNamesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.project_io.Variable.ArrayedEquation.Element.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.ArrayedEquation}
 */
proto.project_io.Variable.ArrayedEquation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.ArrayedEquation;
  return proto.project_io.Variable.ArrayedEquation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.ArrayedEquation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.ArrayedEquation}
 */
proto.project_io.Variable.ArrayedEquation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDimensionNames(value);
      break;
    case 2:
      var value = new proto.project_io.Variable.ArrayedEquation.Element;
      reader.readMessage(value,proto.project_io.Variable.ArrayedEquation.Element.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.ArrayedEquation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.ArrayedEquation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.ArrayedEquation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ArrayedEquation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDimensionNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.project_io.Variable.ArrayedEquation.Element.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.ArrayedEquation.Element.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.ArrayedEquation.Element} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ArrayedEquation.Element.toObject = function(includeInstance, msg) {
  var f, obj = {
    subscript: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    initialEquation: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.ArrayedEquation.Element}
 */
proto.project_io.Variable.ArrayedEquation.Element.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.ArrayedEquation.Element;
  return proto.project_io.Variable.ArrayedEquation.Element.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.ArrayedEquation.Element} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.ArrayedEquation.Element}
 */
proto.project_io.Variable.ArrayedEquation.Element.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscript(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitialEquation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.ArrayedEquation.Element.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.ArrayedEquation.Element} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.ArrayedEquation.Element.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubscript();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subscript = 1;
 * @return {string}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.getSubscript = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ArrayedEquation.Element} returns this
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.setSubscript = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string equation = 2;
 * @return {string}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.getEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ArrayedEquation.Element} returns this
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.setEquation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string initial_equation = 3;
 * @return {string}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.getInitialEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.ArrayedEquation.Element} returns this
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.setInitialEquation = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.project_io.Variable.ArrayedEquation.Element} returns this
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.clearInitialEquation = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.ArrayedEquation.Element.prototype.hasInitialEquation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string dimension_names = 1;
 * @return {!Array<string>}
 */
proto.project_io.Variable.ArrayedEquation.prototype.getDimensionNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Variable.ArrayedEquation} returns this
 */
proto.project_io.Variable.ArrayedEquation.prototype.setDimensionNamesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.ArrayedEquation} returns this
 */
proto.project_io.Variable.ArrayedEquation.prototype.addDimensionNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.ArrayedEquation} returns this
 */
proto.project_io.Variable.ArrayedEquation.prototype.clearDimensionNamesList = function() {
  return this.setDimensionNamesList([]);
};


/**
 * repeated Element elements = 2;
 * @return {!Array<!proto.project_io.Variable.ArrayedEquation.Element>}
 */
proto.project_io.Variable.ArrayedEquation.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.project_io.Variable.ArrayedEquation.Element>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Variable.ArrayedEquation.Element, 2));
};


/**
 * @param {!Array<!proto.project_io.Variable.ArrayedEquation.Element>} value
 * @return {!proto.project_io.Variable.ArrayedEquation} returns this
*/
proto.project_io.Variable.ArrayedEquation.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.project_io.Variable.ArrayedEquation.Element=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.ArrayedEquation.Element}
 */
proto.project_io.Variable.ArrayedEquation.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.project_io.Variable.ArrayedEquation.Element, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.ArrayedEquation} returns this
 */
proto.project_io.Variable.ArrayedEquation.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.project_io.Variable.Equation.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.project_io.Variable.Equation.EquationCase = {
  EQUATION_NOT_SET: 0,
  SCALAR: 1,
  APPLY_TO_ALL: 2,
  ARRAYED: 3
};

/**
 * @return {proto.project_io.Variable.Equation.EquationCase}
 */
proto.project_io.Variable.Equation.prototype.getEquationCase = function() {
  return /** @type {proto.project_io.Variable.Equation.EquationCase} */(jspb.Message.computeOneofCase(this, proto.project_io.Variable.Equation.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Equation.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Equation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Equation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Equation.toObject = function(includeInstance, msg) {
  var f, obj = {
    scalar: (f = msg.getScalar()) && proto.project_io.Variable.ScalarEquation.toObject(includeInstance, f),
    applyToAll: (f = msg.getApplyToAll()) && proto.project_io.Variable.ApplyToAllEquation.toObject(includeInstance, f),
    arrayed: (f = msg.getArrayed()) && proto.project_io.Variable.ArrayedEquation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Equation}
 */
proto.project_io.Variable.Equation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Equation;
  return proto.project_io.Variable.Equation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Equation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Equation}
 */
proto.project_io.Variable.Equation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.project_io.Variable.ScalarEquation;
      reader.readMessage(value,proto.project_io.Variable.ScalarEquation.deserializeBinaryFromReader);
      msg.setScalar(value);
      break;
    case 2:
      var value = new proto.project_io.Variable.ApplyToAllEquation;
      reader.readMessage(value,proto.project_io.Variable.ApplyToAllEquation.deserializeBinaryFromReader);
      msg.setApplyToAll(value);
      break;
    case 3:
      var value = new proto.project_io.Variable.ArrayedEquation;
      reader.readMessage(value,proto.project_io.Variable.ArrayedEquation.deserializeBinaryFromReader);
      msg.setArrayed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Equation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Equation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Equation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Equation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScalar();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.project_io.Variable.ScalarEquation.serializeBinaryToWriter
    );
  }
  f = message.getApplyToAll();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.project_io.Variable.ApplyToAllEquation.serializeBinaryToWriter
    );
  }
  f = message.getArrayed();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.project_io.Variable.ArrayedEquation.serializeBinaryToWriter
    );
  }
};


/**
 * optional ScalarEquation scalar = 1;
 * @return {?proto.project_io.Variable.ScalarEquation}
 */
proto.project_io.Variable.Equation.prototype.getScalar = function() {
  return /** @type{?proto.project_io.Variable.ScalarEquation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.ScalarEquation, 1));
};


/**
 * @param {?proto.project_io.Variable.ScalarEquation|undefined} value
 * @return {!proto.project_io.Variable.Equation} returns this
*/
proto.project_io.Variable.Equation.prototype.setScalar = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.project_io.Variable.Equation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Equation} returns this
 */
proto.project_io.Variable.Equation.prototype.clearScalar = function() {
  return this.setScalar(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Equation.prototype.hasScalar = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ApplyToAllEquation apply_to_all = 2;
 * @return {?proto.project_io.Variable.ApplyToAllEquation}
 */
proto.project_io.Variable.Equation.prototype.getApplyToAll = function() {
  return /** @type{?proto.project_io.Variable.ApplyToAllEquation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.ApplyToAllEquation, 2));
};


/**
 * @param {?proto.project_io.Variable.ApplyToAllEquation|undefined} value
 * @return {!proto.project_io.Variable.Equation} returns this
*/
proto.project_io.Variable.Equation.prototype.setApplyToAll = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.project_io.Variable.Equation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Equation} returns this
 */
proto.project_io.Variable.Equation.prototype.clearApplyToAll = function() {
  return this.setApplyToAll(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Equation.prototype.hasApplyToAll = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ArrayedEquation arrayed = 3;
 * @return {?proto.project_io.Variable.ArrayedEquation}
 */
proto.project_io.Variable.Equation.prototype.getArrayed = function() {
  return /** @type{?proto.project_io.Variable.ArrayedEquation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.ArrayedEquation, 3));
};


/**
 * @param {?proto.project_io.Variable.ArrayedEquation|undefined} value
 * @return {!proto.project_io.Variable.Equation} returns this
*/
proto.project_io.Variable.Equation.prototype.setArrayed = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.project_io.Variable.Equation.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Equation} returns this
 */
proto.project_io.Variable.Equation.prototype.clearArrayed = function() {
  return this.setArrayed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Equation.prototype.hasArrayed = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Variable.Stock.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Stock.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Stock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Stock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Stock.toObject = function(includeInstance, msg) {
  var f, obj = {
    ident: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equation: (f = msg.getEquation()) && proto.project_io.Variable.Equation.toObject(includeInstance, f),
    documentation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    units: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inflowsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    outflowsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    nonNegative: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    canBeModuleInput: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    visibility: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Stock}
 */
proto.project_io.Variable.Stock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Stock;
  return proto.project_io.Variable.Stock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Stock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Stock}
 */
proto.project_io.Variable.Stock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdent(value);
      break;
    case 8:
      var value = new proto.project_io.Variable.Equation;
      reader.readMessage(value,proto.project_io.Variable.Equation.deserializeBinaryFromReader);
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addInflows(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOutflows(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonNegative(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeModuleInput(value);
      break;
    case 10:
      var value = /** @type {!proto.project_io.Variable.Visibility} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Stock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Stock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Stock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Stock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.project_io.Variable.Equation.serializeBinaryToWriter
    );
  }
  f = message.getDocumentation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInflowsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getOutflowsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getNonNegative();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCanBeModuleInput();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string ident = 1;
 * @return {string}
 */
proto.project_io.Variable.Stock.prototype.getIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Equation equation = 8;
 * @return {?proto.project_io.Variable.Equation}
 */
proto.project_io.Variable.Stock.prototype.getEquation = function() {
  return /** @type{?proto.project_io.Variable.Equation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Equation, 8));
};


/**
 * @param {?proto.project_io.Variable.Equation|undefined} value
 * @return {!proto.project_io.Variable.Stock} returns this
*/
proto.project_io.Variable.Stock.prototype.setEquation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.clearEquation = function() {
  return this.setEquation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Stock.prototype.hasEquation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string documentation = 3;
 * @return {string}
 */
proto.project_io.Variable.Stock.prototype.getDocumentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setDocumentation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string units = 4;
 * @return {string}
 */
proto.project_io.Variable.Stock.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string inflows = 5;
 * @return {!Array<string>}
 */
proto.project_io.Variable.Stock.prototype.getInflowsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setInflowsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.addInflows = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.clearInflowsList = function() {
  return this.setInflowsList([]);
};


/**
 * repeated string outflows = 6;
 * @return {!Array<string>}
 */
proto.project_io.Variable.Stock.prototype.getOutflowsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setOutflowsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.addOutflows = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.clearOutflowsList = function() {
  return this.setOutflowsList([]);
};


/**
 * optional bool non_negative = 7;
 * @return {boolean}
 */
proto.project_io.Variable.Stock.prototype.getNonNegative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setNonNegative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool can_be_module_input = 9;
 * @return {boolean}
 */
proto.project_io.Variable.Stock.prototype.getCanBeModuleInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setCanBeModuleInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Visibility visibility = 10;
 * @return {!proto.project_io.Variable.Visibility}
 */
proto.project_io.Variable.Stock.prototype.getVisibility = function() {
  return /** @type {!proto.project_io.Variable.Visibility} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.project_io.Variable.Visibility} value
 * @return {!proto.project_io.Variable.Stock} returns this
 */
proto.project_io.Variable.Stock.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Flow.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Flow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Flow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Flow.toObject = function(includeInstance, msg) {
  var f, obj = {
    ident: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equation: (f = msg.getEquation()) && proto.project_io.Variable.Equation.toObject(includeInstance, f),
    documentation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    units: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gf: (f = msg.getGf()) && proto.project_io.GraphicalFunction.toObject(includeInstance, f),
    nonNegative: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    canBeModuleInput: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    visibility: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Flow}
 */
proto.project_io.Variable.Flow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Flow;
  return proto.project_io.Variable.Flow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Flow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Flow}
 */
proto.project_io.Variable.Flow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdent(value);
      break;
    case 8:
      var value = new proto.project_io.Variable.Equation;
      reader.readMessage(value,proto.project_io.Variable.Equation.deserializeBinaryFromReader);
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 5:
      var value = new proto.project_io.GraphicalFunction;
      reader.readMessage(value,proto.project_io.GraphicalFunction.deserializeBinaryFromReader);
      msg.setGf(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNonNegative(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeModuleInput(value);
      break;
    case 10:
      var value = /** @type {!proto.project_io.Variable.Visibility} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Flow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Flow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Flow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Flow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.project_io.Variable.Equation.serializeBinaryToWriter
    );
  }
  f = message.getDocumentation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGf();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.project_io.GraphicalFunction.serializeBinaryToWriter
    );
  }
  f = message.getNonNegative();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getCanBeModuleInput();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string ident = 1;
 * @return {string}
 */
proto.project_io.Variable.Flow.prototype.getIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Equation equation = 8;
 * @return {?proto.project_io.Variable.Equation}
 */
proto.project_io.Variable.Flow.prototype.getEquation = function() {
  return /** @type{?proto.project_io.Variable.Equation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Equation, 8));
};


/**
 * @param {?proto.project_io.Variable.Equation|undefined} value
 * @return {!proto.project_io.Variable.Flow} returns this
*/
proto.project_io.Variable.Flow.prototype.setEquation = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.clearEquation = function() {
  return this.setEquation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Flow.prototype.hasEquation = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string documentation = 3;
 * @return {string}
 */
proto.project_io.Variable.Flow.prototype.getDocumentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setDocumentation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string units = 4;
 * @return {string}
 */
proto.project_io.Variable.Flow.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GraphicalFunction gf = 5;
 * @return {?proto.project_io.GraphicalFunction}
 */
proto.project_io.Variable.Flow.prototype.getGf = function() {
  return /** @type{?proto.project_io.GraphicalFunction} */ (
    jspb.Message.getWrapperField(this, proto.project_io.GraphicalFunction, 5));
};


/**
 * @param {?proto.project_io.GraphicalFunction|undefined} value
 * @return {!proto.project_io.Variable.Flow} returns this
*/
proto.project_io.Variable.Flow.prototype.setGf = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.clearGf = function() {
  return this.setGf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Flow.prototype.hasGf = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool non_negative = 7;
 * @return {boolean}
 */
proto.project_io.Variable.Flow.prototype.getNonNegative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setNonNegative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool can_be_module_input = 9;
 * @return {boolean}
 */
proto.project_io.Variable.Flow.prototype.getCanBeModuleInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setCanBeModuleInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Visibility visibility = 10;
 * @return {!proto.project_io.Variable.Visibility}
 */
proto.project_io.Variable.Flow.prototype.getVisibility = function() {
  return /** @type {!proto.project_io.Variable.Visibility} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.project_io.Variable.Visibility} value
 * @return {!proto.project_io.Variable.Flow} returns this
 */
proto.project_io.Variable.Flow.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Aux.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Aux.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Aux} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Aux.toObject = function(includeInstance, msg) {
  var f, obj = {
    ident: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equation: (f = msg.getEquation()) && proto.project_io.Variable.Equation.toObject(includeInstance, f),
    documentation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    units: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gf: (f = msg.getGf()) && proto.project_io.GraphicalFunction.toObject(includeInstance, f),
    canBeModuleInput: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    visibility: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Aux}
 */
proto.project_io.Variable.Aux.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Aux;
  return proto.project_io.Variable.Aux.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Aux} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Aux}
 */
proto.project_io.Variable.Aux.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdent(value);
      break;
    case 6:
      var value = new proto.project_io.Variable.Equation;
      reader.readMessage(value,proto.project_io.Variable.Equation.deserializeBinaryFromReader);
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 5:
      var value = new proto.project_io.GraphicalFunction;
      reader.readMessage(value,proto.project_io.GraphicalFunction.deserializeBinaryFromReader);
      msg.setGf(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeModuleInput(value);
      break;
    case 8:
      var value = /** @type {!proto.project_io.Variable.Visibility} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Aux.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Aux.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Aux} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Aux.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.project_io.Variable.Equation.serializeBinaryToWriter
    );
  }
  f = message.getDocumentation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGf();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.project_io.GraphicalFunction.serializeBinaryToWriter
    );
  }
  f = message.getCanBeModuleInput();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string ident = 1;
 * @return {string}
 */
proto.project_io.Variable.Aux.prototype.getIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.setIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Equation equation = 6;
 * @return {?proto.project_io.Variable.Equation}
 */
proto.project_io.Variable.Aux.prototype.getEquation = function() {
  return /** @type{?proto.project_io.Variable.Equation} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Equation, 6));
};


/**
 * @param {?proto.project_io.Variable.Equation|undefined} value
 * @return {!proto.project_io.Variable.Aux} returns this
*/
proto.project_io.Variable.Aux.prototype.setEquation = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.clearEquation = function() {
  return this.setEquation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Aux.prototype.hasEquation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string documentation = 3;
 * @return {string}
 */
proto.project_io.Variable.Aux.prototype.getDocumentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.setDocumentation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string units = 4;
 * @return {string}
 */
proto.project_io.Variable.Aux.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.setUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional GraphicalFunction gf = 5;
 * @return {?proto.project_io.GraphicalFunction}
 */
proto.project_io.Variable.Aux.prototype.getGf = function() {
  return /** @type{?proto.project_io.GraphicalFunction} */ (
    jspb.Message.getWrapperField(this, proto.project_io.GraphicalFunction, 5));
};


/**
 * @param {?proto.project_io.GraphicalFunction|undefined} value
 * @return {!proto.project_io.Variable.Aux} returns this
*/
proto.project_io.Variable.Aux.prototype.setGf = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.clearGf = function() {
  return this.setGf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.Aux.prototype.hasGf = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool can_be_module_input = 7;
 * @return {boolean}
 */
proto.project_io.Variable.Aux.prototype.getCanBeModuleInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.setCanBeModuleInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Visibility visibility = 8;
 * @return {!proto.project_io.Variable.Visibility}
 */
proto.project_io.Variable.Aux.prototype.getVisibility = function() {
  return /** @type {!proto.project_io.Variable.Visibility} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.project_io.Variable.Visibility} value
 * @return {!proto.project_io.Variable.Aux} returns this
 */
proto.project_io.Variable.Aux.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Variable.Module.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Module.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Module.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Module} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Module.toObject = function(includeInstance, msg) {
  var f, obj = {
    ident: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    documentation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    units: jspb.Message.getFieldWithDefault(msg, 4, ""),
    referencesList: jspb.Message.toObjectList(msg.getReferencesList(),
    proto.project_io.Variable.Module.Reference.toObject, includeInstance),
    canBeModuleInput: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    visibility: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Module}
 */
proto.project_io.Variable.Module.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Module;
  return proto.project_io.Variable.Module.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Module} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Module}
 */
proto.project_io.Variable.Module.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnits(value);
      break;
    case 5:
      var value = new proto.project_io.Variable.Module.Reference;
      reader.readMessage(value,proto.project_io.Variable.Module.Reference.deserializeBinaryFromReader);
      msg.addReferences(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanBeModuleInput(value);
      break;
    case 7:
      var value = /** @type {!proto.project_io.Variable.Visibility} */ (reader.readEnum());
      msg.setVisibility(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Module.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Module.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Module} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Module.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDocumentation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUnits();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReferencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.project_io.Variable.Module.Reference.serializeBinaryToWriter
    );
  }
  f = message.getCanBeModuleInput();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getVisibility();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Variable.Module.Reference.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Variable.Module.Reference.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Variable.Module.Reference} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Module.Reference.toObject = function(includeInstance, msg) {
  var f, obj = {
    src: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dst: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Variable.Module.Reference}
 */
proto.project_io.Variable.Module.Reference.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Variable.Module.Reference;
  return proto.project_io.Variable.Module.Reference.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Variable.Module.Reference} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Variable.Module.Reference}
 */
proto.project_io.Variable.Module.Reference.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSrc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDst(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Variable.Module.Reference.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Variable.Module.Reference.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Variable.Module.Reference} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Variable.Module.Reference.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSrc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDst();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string src = 1;
 * @return {string}
 */
proto.project_io.Variable.Module.Reference.prototype.getSrc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module.Reference} returns this
 */
proto.project_io.Variable.Module.Reference.prototype.setSrc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dst = 2;
 * @return {string}
 */
proto.project_io.Variable.Module.Reference.prototype.getDst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module.Reference} returns this
 */
proto.project_io.Variable.Module.Reference.prototype.setDst = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ident = 1;
 * @return {string}
 */
proto.project_io.Variable.Module.prototype.getIdent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setIdent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_name = 2;
 * @return {string}
 */
proto.project_io.Variable.Module.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string documentation = 3;
 * @return {string}
 */
proto.project_io.Variable.Module.prototype.getDocumentation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setDocumentation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string units = 4;
 * @return {string}
 */
proto.project_io.Variable.Module.prototype.getUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Reference references = 5;
 * @return {!Array<!proto.project_io.Variable.Module.Reference>}
 */
proto.project_io.Variable.Module.prototype.getReferencesList = function() {
  return /** @type{!Array<!proto.project_io.Variable.Module.Reference>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Variable.Module.Reference, 5));
};


/**
 * @param {!Array<!proto.project_io.Variable.Module.Reference>} value
 * @return {!proto.project_io.Variable.Module} returns this
*/
proto.project_io.Variable.Module.prototype.setReferencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.project_io.Variable.Module.Reference=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable.Module.Reference}
 */
proto.project_io.Variable.Module.prototype.addReferences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.project_io.Variable.Module.Reference, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.clearReferencesList = function() {
  return this.setReferencesList([]);
};


/**
 * optional bool can_be_module_input = 6;
 * @return {boolean}
 */
proto.project_io.Variable.Module.prototype.getCanBeModuleInput = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setCanBeModuleInput = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional Visibility visibility = 7;
 * @return {!proto.project_io.Variable.Visibility}
 */
proto.project_io.Variable.Module.prototype.getVisibility = function() {
  return /** @type {!proto.project_io.Variable.Visibility} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.project_io.Variable.Visibility} value
 * @return {!proto.project_io.Variable.Module} returns this
 */
proto.project_io.Variable.Module.prototype.setVisibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional Stock stock = 1;
 * @return {?proto.project_io.Variable.Stock}
 */
proto.project_io.Variable.prototype.getStock = function() {
  return /** @type{?proto.project_io.Variable.Stock} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Stock, 1));
};


/**
 * @param {?proto.project_io.Variable.Stock|undefined} value
 * @return {!proto.project_io.Variable} returns this
*/
proto.project_io.Variable.prototype.setStock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.project_io.Variable.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable} returns this
 */
proto.project_io.Variable.prototype.clearStock = function() {
  return this.setStock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.prototype.hasStock = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Flow flow = 2;
 * @return {?proto.project_io.Variable.Flow}
 */
proto.project_io.Variable.prototype.getFlow = function() {
  return /** @type{?proto.project_io.Variable.Flow} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Flow, 2));
};


/**
 * @param {?proto.project_io.Variable.Flow|undefined} value
 * @return {!proto.project_io.Variable} returns this
*/
proto.project_io.Variable.prototype.setFlow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.project_io.Variable.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable} returns this
 */
proto.project_io.Variable.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Aux aux = 3;
 * @return {?proto.project_io.Variable.Aux}
 */
proto.project_io.Variable.prototype.getAux = function() {
  return /** @type{?proto.project_io.Variable.Aux} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Aux, 3));
};


/**
 * @param {?proto.project_io.Variable.Aux|undefined} value
 * @return {!proto.project_io.Variable} returns this
*/
proto.project_io.Variable.prototype.setAux = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.project_io.Variable.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable} returns this
 */
proto.project_io.Variable.prototype.clearAux = function() {
  return this.setAux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.prototype.hasAux = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Module module = 4;
 * @return {?proto.project_io.Variable.Module}
 */
proto.project_io.Variable.prototype.getModule = function() {
  return /** @type{?proto.project_io.Variable.Module} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Variable.Module, 4));
};


/**
 * @param {?proto.project_io.Variable.Module|undefined} value
 * @return {!proto.project_io.Variable} returns this
*/
proto.project_io.Variable.prototype.setModule = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.project_io.Variable.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Variable} returns this
 */
proto.project_io.Variable.prototype.clearModule = function() {
  return this.setModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Variable.prototype.hasModule = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.project_io.ViewElement.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.project_io.ViewElement.ElementCase = {
  ELEMENT_NOT_SET: 0,
  AUX: 1,
  STOCK: 2,
  FLOW: 3,
  LINK: 4,
  MODULE: 5,
  ALIAS: 6,
  CLOUD: 7
};

/**
 * @return {proto.project_io.ViewElement.ElementCase}
 */
proto.project_io.ViewElement.prototype.getElementCase = function() {
  return /** @type {proto.project_io.ViewElement.ElementCase} */(jspb.Message.computeOneofCase(this, proto.project_io.ViewElement.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.toObject = function(includeInstance, msg) {
  var f, obj = {
    aux: (f = msg.getAux()) && proto.project_io.ViewElement.Aux.toObject(includeInstance, f),
    stock: (f = msg.getStock()) && proto.project_io.ViewElement.Stock.toObject(includeInstance, f),
    flow: (f = msg.getFlow()) && proto.project_io.ViewElement.Flow.toObject(includeInstance, f),
    link: (f = msg.getLink()) && proto.project_io.ViewElement.Link.toObject(includeInstance, f),
    module: (f = msg.getModule()) && proto.project_io.ViewElement.Module.toObject(includeInstance, f),
    alias: (f = msg.getAlias()) && proto.project_io.ViewElement.Alias.toObject(includeInstance, f),
    cloud: (f = msg.getCloud()) && proto.project_io.ViewElement.Cloud.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement}
 */
proto.project_io.ViewElement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement;
  return proto.project_io.ViewElement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement}
 */
proto.project_io.ViewElement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.project_io.ViewElement.Aux;
      reader.readMessage(value,proto.project_io.ViewElement.Aux.deserializeBinaryFromReader);
      msg.setAux(value);
      break;
    case 2:
      var value = new proto.project_io.ViewElement.Stock;
      reader.readMessage(value,proto.project_io.ViewElement.Stock.deserializeBinaryFromReader);
      msg.setStock(value);
      break;
    case 3:
      var value = new proto.project_io.ViewElement.Flow;
      reader.readMessage(value,proto.project_io.ViewElement.Flow.deserializeBinaryFromReader);
      msg.setFlow(value);
      break;
    case 4:
      var value = new proto.project_io.ViewElement.Link;
      reader.readMessage(value,proto.project_io.ViewElement.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    case 5:
      var value = new proto.project_io.ViewElement.Module;
      reader.readMessage(value,proto.project_io.ViewElement.Module.deserializeBinaryFromReader);
      msg.setModule(value);
      break;
    case 6:
      var value = new proto.project_io.ViewElement.Alias;
      reader.readMessage(value,proto.project_io.ViewElement.Alias.deserializeBinaryFromReader);
      msg.setAlias(value);
      break;
    case 7:
      var value = new proto.project_io.ViewElement.Cloud;
      reader.readMessage(value,proto.project_io.ViewElement.Cloud.deserializeBinaryFromReader);
      msg.setCloud(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAux();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.project_io.ViewElement.Aux.serializeBinaryToWriter
    );
  }
  f = message.getStock();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.project_io.ViewElement.Stock.serializeBinaryToWriter
    );
  }
  f = message.getFlow();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.project_io.ViewElement.Flow.serializeBinaryToWriter
    );
  }
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.ViewElement.Link.serializeBinaryToWriter
    );
  }
  f = message.getModule();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.project_io.ViewElement.Module.serializeBinaryToWriter
    );
  }
  f = message.getAlias();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.project_io.ViewElement.Alias.serializeBinaryToWriter
    );
  }
  f = message.getCloud();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.project_io.ViewElement.Cloud.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.project_io.ViewElement.LabelSide = {
  TOP: 0,
  LEFT: 1,
  CENTER: 2,
  BOTTOM: 3,
  RIGHT: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Aux.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Aux.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Aux} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Aux.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    labelSide: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Aux}
 */
proto.project_io.ViewElement.Aux.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Aux;
  return proto.project_io.ViewElement.Aux.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Aux} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Aux}
 */
proto.project_io.ViewElement.Aux.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.ViewElement.LabelSide} */ (reader.readEnum());
      msg.setLabelSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Aux.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Aux.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Aux} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Aux.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLabelSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.ViewElement.Aux.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.ViewElement.Aux} returns this
 */
proto.project_io.ViewElement.Aux.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Aux.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Aux} returns this
 */
proto.project_io.ViewElement.Aux.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Aux.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Aux} returns this
 */
proto.project_io.ViewElement.Aux.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Aux.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Aux} returns this
 */
proto.project_io.ViewElement.Aux.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional LabelSide label_side = 5;
 * @return {!proto.project_io.ViewElement.LabelSide}
 */
proto.project_io.ViewElement.Aux.prototype.getLabelSide = function() {
  return /** @type {!proto.project_io.ViewElement.LabelSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.ViewElement.LabelSide} value
 * @return {!proto.project_io.ViewElement.Aux} returns this
 */
proto.project_io.ViewElement.Aux.prototype.setLabelSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Stock.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Stock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Stock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Stock.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    labelSide: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Stock}
 */
proto.project_io.ViewElement.Stock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Stock;
  return proto.project_io.ViewElement.Stock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Stock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Stock}
 */
proto.project_io.ViewElement.Stock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.ViewElement.LabelSide} */ (reader.readEnum());
      msg.setLabelSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Stock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Stock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Stock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Stock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLabelSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.ViewElement.Stock.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.ViewElement.Stock} returns this
 */
proto.project_io.ViewElement.Stock.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Stock.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Stock} returns this
 */
proto.project_io.ViewElement.Stock.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Stock.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Stock} returns this
 */
proto.project_io.ViewElement.Stock.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Stock.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Stock} returns this
 */
proto.project_io.ViewElement.Stock.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional LabelSide label_side = 5;
 * @return {!proto.project_io.ViewElement.LabelSide}
 */
proto.project_io.ViewElement.Stock.prototype.getLabelSide = function() {
  return /** @type {!proto.project_io.ViewElement.LabelSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.ViewElement.LabelSide} value
 * @return {!proto.project_io.ViewElement.Stock} returns this
 */
proto.project_io.ViewElement.Stock.prototype.setLabelSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.FlowPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.FlowPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.FlowPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.FlowPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    attachedToUid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.FlowPoint}
 */
proto.project_io.ViewElement.FlowPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.FlowPoint;
  return proto.project_io.ViewElement.FlowPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.FlowPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.FlowPoint}
 */
proto.project_io.ViewElement.FlowPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAttachedToUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.FlowPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.FlowPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.FlowPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.FlowPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAttachedToUid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.project_io.ViewElement.FlowPoint.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.FlowPoint} returns this
 */
proto.project_io.ViewElement.FlowPoint.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.project_io.ViewElement.FlowPoint.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.FlowPoint} returns this
 */
proto.project_io.ViewElement.FlowPoint.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 attached_to_uid = 3;
 * @return {number}
 */
proto.project_io.ViewElement.FlowPoint.prototype.getAttachedToUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.FlowPoint} returns this
 */
proto.project_io.ViewElement.FlowPoint.prototype.setAttachedToUid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.ViewElement.Flow.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Flow.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Flow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Flow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Flow.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    labelSide: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.project_io.ViewElement.FlowPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Flow}
 */
proto.project_io.ViewElement.Flow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Flow;
  return proto.project_io.ViewElement.Flow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Flow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Flow}
 */
proto.project_io.ViewElement.Flow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.ViewElement.LabelSide} */ (reader.readEnum());
      msg.setLabelSide(value);
      break;
    case 6:
      var value = new proto.project_io.ViewElement.FlowPoint;
      reader.readMessage(value,proto.project_io.ViewElement.FlowPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Flow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Flow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Flow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Flow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLabelSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.project_io.ViewElement.FlowPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.ViewElement.Flow.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Flow.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Flow.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Flow.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional LabelSide label_side = 5;
 * @return {!proto.project_io.ViewElement.LabelSide}
 */
proto.project_io.ViewElement.Flow.prototype.getLabelSide = function() {
  return /** @type {!proto.project_io.ViewElement.LabelSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.ViewElement.LabelSide} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.setLabelSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated FlowPoint points = 6;
 * @return {!Array<!proto.project_io.ViewElement.FlowPoint>}
 */
proto.project_io.ViewElement.Flow.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.project_io.ViewElement.FlowPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.ViewElement.FlowPoint, 6));
};


/**
 * @param {!Array<!proto.project_io.ViewElement.FlowPoint>} value
 * @return {!proto.project_io.ViewElement.Flow} returns this
*/
proto.project_io.ViewElement.Flow.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.project_io.ViewElement.FlowPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.ViewElement.FlowPoint}
 */
proto.project_io.ViewElement.Flow.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.project_io.ViewElement.FlowPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.ViewElement.Flow} returns this
 */
proto.project_io.ViewElement.Flow.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.project_io.ViewElement.Link.oneofGroups_ = [[4,5,6]];

/**
 * @enum {number}
 */
proto.project_io.ViewElement.Link.ShapeCase = {
  SHAPE_NOT_SET: 0,
  ARC: 4,
  IS_STRAIGHT: 5,
  MULTI_POINT: 6
};

/**
 * @return {proto.project_io.ViewElement.Link.ShapeCase}
 */
proto.project_io.ViewElement.Link.prototype.getShapeCase = function() {
  return /** @type {proto.project_io.ViewElement.Link.ShapeCase} */(jspb.Message.computeOneofCase(this, proto.project_io.ViewElement.Link.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromUid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toUid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    arc: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    isStraight: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    multiPoint: (f = msg.getMultiPoint()) && proto.project_io.ViewElement.Link.LinkPoints.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Link}
 */
proto.project_io.ViewElement.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Link;
  return proto.project_io.ViewElement.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Link}
 */
proto.project_io.ViewElement.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setToUid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setArc(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStraight(value);
      break;
    case 6:
      var value = new proto.project_io.ViewElement.Link.LinkPoints;
      reader.readMessage(value,proto.project_io.ViewElement.Link.LinkPoints.deserializeBinaryFromReader);
      msg.setMultiPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFromUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getToUid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMultiPoint();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.project_io.ViewElement.Link.LinkPoints.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.ViewElement.Link.LinkPoints.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Link.LinkPoints.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Link.LinkPoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Link.LinkPoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Link.LinkPoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.project_io.ViewElement.FlowPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Link.LinkPoints}
 */
proto.project_io.ViewElement.Link.LinkPoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Link.LinkPoints;
  return proto.project_io.ViewElement.Link.LinkPoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Link.LinkPoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Link.LinkPoints}
 */
proto.project_io.ViewElement.Link.LinkPoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.project_io.ViewElement.FlowPoint;
      reader.readMessage(value,proto.project_io.ViewElement.FlowPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Link.LinkPoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Link.LinkPoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Link.LinkPoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Link.LinkPoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.project_io.ViewElement.FlowPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FlowPoint points = 1;
 * @return {!Array<!proto.project_io.ViewElement.FlowPoint>}
 */
proto.project_io.ViewElement.Link.LinkPoints.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.project_io.ViewElement.FlowPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.ViewElement.FlowPoint, 1));
};


/**
 * @param {!Array<!proto.project_io.ViewElement.FlowPoint>} value
 * @return {!proto.project_io.ViewElement.Link.LinkPoints} returns this
*/
proto.project_io.ViewElement.Link.LinkPoints.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.project_io.ViewElement.FlowPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.ViewElement.FlowPoint}
 */
proto.project_io.ViewElement.Link.LinkPoints.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.project_io.ViewElement.FlowPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.ViewElement.Link.LinkPoints} returns this
 */
proto.project_io.ViewElement.Link.LinkPoints.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};


/**
 * optional int32 uid = 1;
 * @return {number}
 */
proto.project_io.ViewElement.Link.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 from_uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Link.prototype.getFromUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.setFromUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 to_uid = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Link.prototype.getToUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.setToUid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double arc = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Link.prototype.getArc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.setArc = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.project_io.ViewElement.Link.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.clearArc = function() {
  return jspb.Message.setOneofField(this, 4, proto.project_io.ViewElement.Link.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.Link.prototype.hasArc = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool is_straight = 5;
 * @return {boolean}
 */
proto.project_io.ViewElement.Link.prototype.getIsStraight = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.setIsStraight = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.project_io.ViewElement.Link.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.clearIsStraight = function() {
  return jspb.Message.setOneofField(this, 5, proto.project_io.ViewElement.Link.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.Link.prototype.hasIsStraight = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LinkPoints multi_point = 6;
 * @return {?proto.project_io.ViewElement.Link.LinkPoints}
 */
proto.project_io.ViewElement.Link.prototype.getMultiPoint = function() {
  return /** @type{?proto.project_io.ViewElement.Link.LinkPoints} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Link.LinkPoints, 6));
};


/**
 * @param {?proto.project_io.ViewElement.Link.LinkPoints|undefined} value
 * @return {!proto.project_io.ViewElement.Link} returns this
*/
proto.project_io.ViewElement.Link.prototype.setMultiPoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.project_io.ViewElement.Link.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement.Link} returns this
 */
proto.project_io.ViewElement.Link.prototype.clearMultiPoint = function() {
  return this.setMultiPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.Link.prototype.hasMultiPoint = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Module.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Module.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Module} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Module.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    labelSide: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Module}
 */
proto.project_io.ViewElement.Module.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Module;
  return proto.project_io.ViewElement.Module.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Module} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Module}
 */
proto.project_io.ViewElement.Module.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.ViewElement.LabelSide} */ (reader.readEnum());
      msg.setLabelSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Module.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Module.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Module} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Module.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLabelSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.ViewElement.Module.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.ViewElement.Module} returns this
 */
proto.project_io.ViewElement.Module.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Module.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Module} returns this
 */
proto.project_io.ViewElement.Module.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Module.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Module} returns this
 */
proto.project_io.ViewElement.Module.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Module.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Module} returns this
 */
proto.project_io.ViewElement.Module.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional LabelSide label_side = 5;
 * @return {!proto.project_io.ViewElement.LabelSide}
 */
proto.project_io.ViewElement.Module.prototype.getLabelSide = function() {
  return /** @type {!proto.project_io.ViewElement.LabelSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.ViewElement.LabelSide} value
 * @return {!proto.project_io.ViewElement.Module} returns this
 */
proto.project_io.ViewElement.Module.prototype.setLabelSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Alias.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Alias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Alias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Alias.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    aliasOfUid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    labelSide: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Alias}
 */
proto.project_io.ViewElement.Alias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Alias;
  return proto.project_io.ViewElement.Alias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Alias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Alias}
 */
proto.project_io.ViewElement.Alias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAliasOfUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.ViewElement.LabelSide} */ (reader.readEnum());
      msg.setLabelSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Alias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Alias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Alias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Alias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAliasOfUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLabelSide();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional int32 uid = 1;
 * @return {number}
 */
proto.project_io.ViewElement.Alias.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Alias} returns this
 */
proto.project_io.ViewElement.Alias.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 alias_of_uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Alias.prototype.getAliasOfUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Alias} returns this
 */
proto.project_io.ViewElement.Alias.prototype.setAliasOfUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Alias.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Alias} returns this
 */
proto.project_io.ViewElement.Alias.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Alias.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Alias} returns this
 */
proto.project_io.ViewElement.Alias.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional LabelSide label_side = 5;
 * @return {!proto.project_io.ViewElement.LabelSide}
 */
proto.project_io.ViewElement.Alias.prototype.getLabelSide = function() {
  return /** @type {!proto.project_io.ViewElement.LabelSide} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.ViewElement.LabelSide} value
 * @return {!proto.project_io.ViewElement.Alias} returns this
 */
proto.project_io.ViewElement.Alias.prototype.setLabelSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.ViewElement.Cloud.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.ViewElement.Cloud.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.ViewElement.Cloud} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Cloud.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    flowUid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.ViewElement.Cloud}
 */
proto.project_io.ViewElement.Cloud.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.ViewElement.Cloud;
  return proto.project_io.ViewElement.Cloud.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.ViewElement.Cloud} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.ViewElement.Cloud}
 */
proto.project_io.ViewElement.Cloud.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlowUid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.ViewElement.Cloud.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.ViewElement.Cloud.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.ViewElement.Cloud} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.ViewElement.Cloud.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFlowUid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int32 uid = 1;
 * @return {number}
 */
proto.project_io.ViewElement.Cloud.prototype.getUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Cloud} returns this
 */
proto.project_io.ViewElement.Cloud.prototype.setUid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 flow_uid = 2;
 * @return {number}
 */
proto.project_io.ViewElement.Cloud.prototype.getFlowUid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Cloud} returns this
 */
proto.project_io.ViewElement.Cloud.prototype.setFlowUid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double x = 3;
 * @return {number}
 */
proto.project_io.ViewElement.Cloud.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Cloud} returns this
 */
proto.project_io.ViewElement.Cloud.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double y = 4;
 * @return {number}
 */
proto.project_io.ViewElement.Cloud.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.ViewElement.Cloud} returns this
 */
proto.project_io.ViewElement.Cloud.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional Aux aux = 1;
 * @return {?proto.project_io.ViewElement.Aux}
 */
proto.project_io.ViewElement.prototype.getAux = function() {
  return /** @type{?proto.project_io.ViewElement.Aux} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Aux, 1));
};


/**
 * @param {?proto.project_io.ViewElement.Aux|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setAux = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearAux = function() {
  return this.setAux(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasAux = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Stock stock = 2;
 * @return {?proto.project_io.ViewElement.Stock}
 */
proto.project_io.ViewElement.prototype.getStock = function() {
  return /** @type{?proto.project_io.ViewElement.Stock} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Stock, 2));
};


/**
 * @param {?proto.project_io.ViewElement.Stock|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setStock = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearStock = function() {
  return this.setStock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasStock = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Flow flow = 3;
 * @return {?proto.project_io.ViewElement.Flow}
 */
proto.project_io.ViewElement.prototype.getFlow = function() {
  return /** @type{?proto.project_io.ViewElement.Flow} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Flow, 3));
};


/**
 * @param {?proto.project_io.ViewElement.Flow|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setFlow = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearFlow = function() {
  return this.setFlow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasFlow = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Link link = 4;
 * @return {?proto.project_io.ViewElement.Link}
 */
proto.project_io.ViewElement.prototype.getLink = function() {
  return /** @type{?proto.project_io.ViewElement.Link} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Link, 4));
};


/**
 * @param {?proto.project_io.ViewElement.Link|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasLink = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Module module = 5;
 * @return {?proto.project_io.ViewElement.Module}
 */
proto.project_io.ViewElement.prototype.getModule = function() {
  return /** @type{?proto.project_io.ViewElement.Module} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Module, 5));
};


/**
 * @param {?proto.project_io.ViewElement.Module|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setModule = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearModule = function() {
  return this.setModule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasModule = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Alias alias = 6;
 * @return {?proto.project_io.ViewElement.Alias}
 */
proto.project_io.ViewElement.prototype.getAlias = function() {
  return /** @type{?proto.project_io.ViewElement.Alias} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Alias, 6));
};


/**
 * @param {?proto.project_io.ViewElement.Alias|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setAlias = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearAlias = function() {
  return this.setAlias(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Cloud cloud = 7;
 * @return {?proto.project_io.ViewElement.Cloud}
 */
proto.project_io.ViewElement.prototype.getCloud = function() {
  return /** @type{?proto.project_io.ViewElement.Cloud} */ (
    jspb.Message.getWrapperField(this, proto.project_io.ViewElement.Cloud, 7));
};


/**
 * @param {?proto.project_io.ViewElement.Cloud|undefined} value
 * @return {!proto.project_io.ViewElement} returns this
*/
proto.project_io.ViewElement.prototype.setCloud = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.project_io.ViewElement.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.ViewElement} returns this
 */
proto.project_io.ViewElement.prototype.clearCloud = function() {
  return this.setCloud(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.ViewElement.prototype.hasCloud = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Rect.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Rect.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Rect} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Rect.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    height: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Rect}
 */
proto.project_io.Rect.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Rect;
  return proto.project_io.Rect.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Rect} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Rect}
 */
proto.project_io.Rect.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Rect.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Rect.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Rect} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Rect.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.project_io.Rect.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Rect} returns this
 */
proto.project_io.Rect.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.project_io.Rect.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Rect} returns this
 */
proto.project_io.Rect.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double width = 3;
 * @return {number}
 */
proto.project_io.Rect.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Rect} returns this
 */
proto.project_io.Rect.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double height = 4;
 * @return {number}
 */
proto.project_io.Rect.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Rect} returns this
 */
proto.project_io.Rect.prototype.setHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.View.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.View.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.View.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.View} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.View.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    elementsList: jspb.Message.toObjectList(msg.getElementsList(),
    proto.project_io.ViewElement.toObject, includeInstance),
    viewbox: (f = msg.getViewbox()) && proto.project_io.Rect.toObject(includeInstance, f),
    zoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.View}
 */
proto.project_io.View.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.View;
  return proto.project_io.View.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.View} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.View}
 */
proto.project_io.View.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.project_io.View.ViewType} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new proto.project_io.ViewElement;
      reader.readMessage(value,proto.project_io.ViewElement.deserializeBinaryFromReader);
      msg.addElements(value);
      break;
    case 4:
      var value = new proto.project_io.Rect;
      reader.readMessage(value,proto.project_io.Rect.deserializeBinaryFromReader);
      msg.setViewbox(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZoom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.View.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.View.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.View} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.View.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.project_io.ViewElement.serializeBinaryToWriter
    );
  }
  f = message.getViewbox();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.Rect.serializeBinaryToWriter
    );
  }
  f = message.getZoom();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.project_io.View.ViewType = {
  STOCK_FLOW: 0
};

/**
 * optional ViewType kind = 1;
 * @return {!proto.project_io.View.ViewType}
 */
proto.project_io.View.prototype.getKind = function() {
  return /** @type {!proto.project_io.View.ViewType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.project_io.View.ViewType} value
 * @return {!proto.project_io.View} returns this
 */
proto.project_io.View.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated ViewElement elements = 3;
 * @return {!Array<!proto.project_io.ViewElement>}
 */
proto.project_io.View.prototype.getElementsList = function() {
  return /** @type{!Array<!proto.project_io.ViewElement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.ViewElement, 3));
};


/**
 * @param {!Array<!proto.project_io.ViewElement>} value
 * @return {!proto.project_io.View} returns this
*/
proto.project_io.View.prototype.setElementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.project_io.ViewElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.ViewElement}
 */
proto.project_io.View.prototype.addElements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.project_io.ViewElement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.View} returns this
 */
proto.project_io.View.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};


/**
 * optional Rect viewBox = 4;
 * @return {?proto.project_io.Rect}
 */
proto.project_io.View.prototype.getViewbox = function() {
  return /** @type{?proto.project_io.Rect} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Rect, 4));
};


/**
 * @param {?proto.project_io.Rect|undefined} value
 * @return {!proto.project_io.View} returns this
*/
proto.project_io.View.prototype.setViewbox = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.View} returns this
 */
proto.project_io.View.prototype.clearViewbox = function() {
  return this.setViewbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.View.prototype.hasViewbox = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double zoom = 5;
 * @return {number}
 */
proto.project_io.View.prototype.getZoom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.View} returns this
 */
proto.project_io.View.prototype.setZoom = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Model.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    variablesList: jspb.Message.toObjectList(msg.getVariablesList(),
    proto.project_io.Variable.toObject, includeInstance),
    viewsList: jspb.Message.toObjectList(msg.getViewsList(),
    proto.project_io.View.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Model}
 */
proto.project_io.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Model;
  return proto.project_io.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Model}
 */
proto.project_io.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.project_io.Variable;
      reader.readMessage(value,proto.project_io.Variable.deserializeBinaryFromReader);
      msg.addVariables(value);
      break;
    case 4:
      var value = new proto.project_io.View;
      reader.readMessage(value,proto.project_io.View.deserializeBinaryFromReader);
      msg.addViews(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVariablesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.project_io.Variable.serializeBinaryToWriter
    );
  }
  f = message.getViewsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.project_io.View.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.Model.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Model} returns this
 */
proto.project_io.Model.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Variable variables = 3;
 * @return {!Array<!proto.project_io.Variable>}
 */
proto.project_io.Model.prototype.getVariablesList = function() {
  return /** @type{!Array<!proto.project_io.Variable>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Variable, 3));
};


/**
 * @param {!Array<!proto.project_io.Variable>} value
 * @return {!proto.project_io.Model} returns this
*/
proto.project_io.Model.prototype.setVariablesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.project_io.Variable=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Variable}
 */
proto.project_io.Model.prototype.addVariables = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.project_io.Variable, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Model} returns this
 */
proto.project_io.Model.prototype.clearVariablesList = function() {
  return this.setVariablesList([]);
};


/**
 * repeated View views = 4;
 * @return {!Array<!proto.project_io.View>}
 */
proto.project_io.Model.prototype.getViewsList = function() {
  return /** @type{!Array<!proto.project_io.View>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.View, 4));
};


/**
 * @param {!Array<!proto.project_io.View>} value
 * @return {!proto.project_io.Model} returns this
*/
proto.project_io.Model.prototype.setViewsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.project_io.View=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.View}
 */
proto.project_io.Model.prototype.addViews = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.project_io.View, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Model} returns this
 */
proto.project_io.Model.prototype.clearViewsList = function() {
  return this.setViewsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Dt.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Dt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Dt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dt.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    isReciprocal: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Dt}
 */
proto.project_io.Dt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Dt;
  return proto.project_io.Dt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Dt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Dt}
 */
proto.project_io.Dt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReciprocal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Dt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Dt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Dt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getIsReciprocal();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.project_io.Dt.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Dt} returns this
 */
proto.project_io.Dt.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional bool is_reciprocal = 2;
 * @return {boolean}
 */
proto.project_io.Dt.prototype.getIsReciprocal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Dt} returns this
 */
proto.project_io.Dt.prototype.setIsReciprocal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.SimSpecs.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.SimSpecs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.SimSpecs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.SimSpecs.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    stop: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    dt: (f = msg.getDt()) && proto.project_io.Dt.toObject(includeInstance, f),
    saveStep: (f = msg.getSaveStep()) && proto.project_io.Dt.toObject(includeInstance, f),
    simMethod: jspb.Message.getFieldWithDefault(msg, 5, 0),
    timeUnits: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.SimSpecs}
 */
proto.project_io.SimSpecs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.SimSpecs;
  return proto.project_io.SimSpecs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.SimSpecs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.SimSpecs}
 */
proto.project_io.SimSpecs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStop(value);
      break;
    case 3:
      var value = new proto.project_io.Dt;
      reader.readMessage(value,proto.project_io.Dt.deserializeBinaryFromReader);
      msg.setDt(value);
      break;
    case 4:
      var value = new proto.project_io.Dt;
      reader.readMessage(value,proto.project_io.Dt.deserializeBinaryFromReader);
      msg.setSaveStep(value);
      break;
    case 5:
      var value = /** @type {!proto.project_io.SimMethod} */ (reader.readEnum());
      msg.setSimMethod(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeUnits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.SimSpecs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.SimSpecs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.SimSpecs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.SimSpecs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getStop();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.project_io.Dt.serializeBinaryToWriter
    );
  }
  f = message.getSaveStep();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.Dt.serializeBinaryToWriter
    );
  }
  f = message.getSimMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTimeUnits();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional double start = 1;
 * @return {number}
 */
proto.project_io.SimSpecs.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.setStart = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double stop = 2;
 * @return {number}
 */
proto.project_io.SimSpecs.prototype.getStop = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.setStop = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Dt dt = 3;
 * @return {?proto.project_io.Dt}
 */
proto.project_io.SimSpecs.prototype.getDt = function() {
  return /** @type{?proto.project_io.Dt} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Dt, 3));
};


/**
 * @param {?proto.project_io.Dt|undefined} value
 * @return {!proto.project_io.SimSpecs} returns this
*/
proto.project_io.SimSpecs.prototype.setDt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.clearDt = function() {
  return this.setDt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.SimSpecs.prototype.hasDt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Dt save_step = 4;
 * @return {?proto.project_io.Dt}
 */
proto.project_io.SimSpecs.prototype.getSaveStep = function() {
  return /** @type{?proto.project_io.Dt} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Dt, 4));
};


/**
 * @param {?proto.project_io.Dt|undefined} value
 * @return {!proto.project_io.SimSpecs} returns this
*/
proto.project_io.SimSpecs.prototype.setSaveStep = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.clearSaveStep = function() {
  return this.setSaveStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.SimSpecs.prototype.hasSaveStep = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SimMethod sim_method = 5;
 * @return {!proto.project_io.SimMethod}
 */
proto.project_io.SimSpecs.prototype.getSimMethod = function() {
  return /** @type {!proto.project_io.SimMethod} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.project_io.SimMethod} value
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.setSimMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string time_units = 6;
 * @return {string}
 */
proto.project_io.SimSpecs.prototype.getTimeUnits = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.SimSpecs} returns this
 */
proto.project_io.SimSpecs.prototype.setTimeUnits = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Dimension.repeatedFields_ = [2];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.project_io.Dimension.oneofGroups_ = [[3,4]];

/**
 * @enum {number}
 */
proto.project_io.Dimension.DimensionCase = {
  DIMENSION_NOT_SET: 0,
  ELEMENTS: 3,
  SIZE: 4
};

/**
 * @return {proto.project_io.Dimension.DimensionCase}
 */
proto.project_io.Dimension.prototype.getDimensionCase = function() {
  return /** @type {proto.project_io.Dimension.DimensionCase} */(jspb.Message.computeOneofCase(this, proto.project_io.Dimension.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Dimension.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Dimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Dimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    obsoleteElementsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    elements: (f = msg.getElements()) && proto.project_io.Dimension.DimensionElements.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.project_io.Dimension.DimensionSize.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Dimension}
 */
proto.project_io.Dimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Dimension;
  return proto.project_io.Dimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Dimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Dimension}
 */
proto.project_io.Dimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addObsoleteElements(value);
      break;
    case 3:
      var value = new proto.project_io.Dimension.DimensionElements;
      reader.readMessage(value,proto.project_io.Dimension.DimensionElements.deserializeBinaryFromReader);
      msg.setElements(value);
      break;
    case 4:
      var value = new proto.project_io.Dimension.DimensionSize;
      reader.readMessage(value,proto.project_io.Dimension.DimensionSize.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Dimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Dimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Dimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getObsoleteElementsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getElements();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.project_io.Dimension.DimensionElements.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.project_io.Dimension.DimensionSize.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Dimension.DimensionElements.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Dimension.DimensionElements.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Dimension.DimensionElements.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Dimension.DimensionElements} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.DimensionElements.toObject = function(includeInstance, msg) {
  var f, obj = {
    elementsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Dimension.DimensionElements}
 */
proto.project_io.Dimension.DimensionElements.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Dimension.DimensionElements;
  return proto.project_io.Dimension.DimensionElements.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Dimension.DimensionElements} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Dimension.DimensionElements}
 */
proto.project_io.Dimension.DimensionElements.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addElements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Dimension.DimensionElements.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Dimension.DimensionElements.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Dimension.DimensionElements} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.DimensionElements.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getElementsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string elements = 1;
 * @return {!Array<string>}
 */
proto.project_io.Dimension.DimensionElements.prototype.getElementsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Dimension.DimensionElements} returns this
 */
proto.project_io.Dimension.DimensionElements.prototype.setElementsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Dimension.DimensionElements} returns this
 */
proto.project_io.Dimension.DimensionElements.prototype.addElements = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Dimension.DimensionElements} returns this
 */
proto.project_io.Dimension.DimensionElements.prototype.clearElementsList = function() {
  return this.setElementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Dimension.DimensionSize.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Dimension.DimensionSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Dimension.DimensionSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.DimensionSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Dimension.DimensionSize}
 */
proto.project_io.Dimension.DimensionSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Dimension.DimensionSize;
  return proto.project_io.Dimension.DimensionSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Dimension.DimensionSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Dimension.DimensionSize}
 */
proto.project_io.Dimension.DimensionSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Dimension.DimensionSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Dimension.DimensionSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Dimension.DimensionSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Dimension.DimensionSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 size = 1;
 * @return {number}
 */
proto.project_io.Dimension.DimensionSize.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.project_io.Dimension.DimensionSize} returns this
 */
proto.project_io.Dimension.DimensionSize.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.Dimension.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string obsolete_elements = 2;
 * @return {!Array<string>}
 */
proto.project_io.Dimension.prototype.getObsoleteElementsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.setObsoleteElementsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.addObsoleteElements = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.clearObsoleteElementsList = function() {
  return this.setObsoleteElementsList([]);
};


/**
 * optional DimensionElements elements = 3;
 * @return {?proto.project_io.Dimension.DimensionElements}
 */
proto.project_io.Dimension.prototype.getElements = function() {
  return /** @type{?proto.project_io.Dimension.DimensionElements} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Dimension.DimensionElements, 3));
};


/**
 * @param {?proto.project_io.Dimension.DimensionElements|undefined} value
 * @return {!proto.project_io.Dimension} returns this
*/
proto.project_io.Dimension.prototype.setElements = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.project_io.Dimension.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.clearElements = function() {
  return this.setElements(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Dimension.prototype.hasElements = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DimensionSize size = 4;
 * @return {?proto.project_io.Dimension.DimensionSize}
 */
proto.project_io.Dimension.prototype.getSize = function() {
  return /** @type{?proto.project_io.Dimension.DimensionSize} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Dimension.DimensionSize, 4));
};


/**
 * @param {?proto.project_io.Dimension.DimensionSize|undefined} value
 * @return {!proto.project_io.Dimension} returns this
*/
proto.project_io.Dimension.prototype.setSize = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.project_io.Dimension.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Dimension} returns this
 */
proto.project_io.Dimension.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Dimension.prototype.hasSize = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Unit.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Unit.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Unit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Unit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Unit.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equation: jspb.Message.getFieldWithDefault(msg, 2, ""),
    disabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    aliasList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Unit}
 */
proto.project_io.Unit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Unit;
  return proto.project_io.Unit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Unit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Unit}
 */
proto.project_io.Unit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquation(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Unit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Unit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Unit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Unit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEquation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAliasList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.Unit.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string equation = 2;
 * @return {string}
 */
proto.project_io.Unit.prototype.getEquation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.setEquation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool disabled = 3;
 * @return {boolean}
 */
proto.project_io.Unit.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.setDisabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string alias = 4;
 * @return {!Array<string>}
 */
proto.project_io.Unit.prototype.getAliasList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.setAliasList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.addAlias = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Unit} returns this
 */
proto.project_io.Unit.prototype.clearAliasList = function() {
  return this.setAliasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    extension: jspb.Message.getFieldWithDefault(msg, 1, 0),
    content: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Source}
 */
proto.project_io.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Source;
  return proto.project_io.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Source}
 */
proto.project_io.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.project_io.Source.Extension} */ (reader.readEnum());
      msg.setExtension$(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExtension$();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.project_io.Source.Extension = {
  UNSPECIFIED: 0,
  XMILE: 1,
  VENSIM: 2
};

/**
 * optional Extension extension = 1;
 * @return {!proto.project_io.Source.Extension}
 */
proto.project_io.Source.prototype.getExtension$ = function() {
  return /** @type {!proto.project_io.Source.Extension} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.project_io.Source.Extension} value
 * @return {!proto.project_io.Source} returns this
 */
proto.project_io.Source.prototype.setExtension$ = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.project_io.Source.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Source} returns this
 */
proto.project_io.Source.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.project_io.Project.repeatedFields_ = [4,6,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.project_io.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.project_io.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.project_io.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    simSpecs: (f = msg.getSimSpecs()) && proto.project_io.SimSpecs.toObject(includeInstance, f),
    dimensionsList: jspb.Message.toObjectList(msg.getDimensionsList(),
    proto.project_io.Dimension.toObject, includeInstance),
    unitsList: jspb.Message.toObjectList(msg.getUnitsList(),
    proto.project_io.Unit.toObject, includeInstance),
    modelsList: jspb.Message.toObjectList(msg.getModelsList(),
    proto.project_io.Model.toObject, includeInstance),
    source: (f = msg.getSource()) && proto.project_io.Source.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.project_io.Project}
 */
proto.project_io.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.project_io.Project;
  return proto.project_io.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.project_io.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.project_io.Project}
 */
proto.project_io.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.project_io.SimSpecs;
      reader.readMessage(value,proto.project_io.SimSpecs.deserializeBinaryFromReader);
      msg.setSimSpecs(value);
      break;
    case 4:
      var value = new proto.project_io.Dimension;
      reader.readMessage(value,proto.project_io.Dimension.deserializeBinaryFromReader);
      msg.addDimensions(value);
      break;
    case 6:
      var value = new proto.project_io.Unit;
      reader.readMessage(value,proto.project_io.Unit.deserializeBinaryFromReader);
      msg.addUnits(value);
      break;
    case 3:
      var value = new proto.project_io.Model;
      reader.readMessage(value,proto.project_io.Model.deserializeBinaryFromReader);
      msg.addModels(value);
      break;
    case 5:
      var value = new proto.project_io.Source;
      reader.readMessage(value,proto.project_io.Source.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.project_io.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.project_io.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.project_io.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.project_io.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSimSpecs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.project_io.SimSpecs.serializeBinaryToWriter
    );
  }
  f = message.getDimensionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.project_io.Dimension.serializeBinaryToWriter
    );
  }
  f = message.getUnitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.project_io.Unit.serializeBinaryToWriter
    );
  }
  f = message.getModelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.project_io.Model.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.project_io.Source.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.project_io.Project.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SimSpecs sim_specs = 2;
 * @return {?proto.project_io.SimSpecs}
 */
proto.project_io.Project.prototype.getSimSpecs = function() {
  return /** @type{?proto.project_io.SimSpecs} */ (
    jspb.Message.getWrapperField(this, proto.project_io.SimSpecs, 2));
};


/**
 * @param {?proto.project_io.SimSpecs|undefined} value
 * @return {!proto.project_io.Project} returns this
*/
proto.project_io.Project.prototype.setSimSpecs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.clearSimSpecs = function() {
  return this.setSimSpecs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Project.prototype.hasSimSpecs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Dimension dimensions = 4;
 * @return {!Array<!proto.project_io.Dimension>}
 */
proto.project_io.Project.prototype.getDimensionsList = function() {
  return /** @type{!Array<!proto.project_io.Dimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Dimension, 4));
};


/**
 * @param {!Array<!proto.project_io.Dimension>} value
 * @return {!proto.project_io.Project} returns this
*/
proto.project_io.Project.prototype.setDimensionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.project_io.Dimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Dimension}
 */
proto.project_io.Project.prototype.addDimensions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.project_io.Dimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.clearDimensionsList = function() {
  return this.setDimensionsList([]);
};


/**
 * repeated Unit units = 6;
 * @return {!Array<!proto.project_io.Unit>}
 */
proto.project_io.Project.prototype.getUnitsList = function() {
  return /** @type{!Array<!proto.project_io.Unit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Unit, 6));
};


/**
 * @param {!Array<!proto.project_io.Unit>} value
 * @return {!proto.project_io.Project} returns this
*/
proto.project_io.Project.prototype.setUnitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.project_io.Unit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Unit}
 */
proto.project_io.Project.prototype.addUnits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.project_io.Unit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.clearUnitsList = function() {
  return this.setUnitsList([]);
};


/**
 * repeated Model models = 3;
 * @return {!Array<!proto.project_io.Model>}
 */
proto.project_io.Project.prototype.getModelsList = function() {
  return /** @type{!Array<!proto.project_io.Model>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.project_io.Model, 3));
};


/**
 * @param {!Array<!proto.project_io.Model>} value
 * @return {!proto.project_io.Project} returns this
*/
proto.project_io.Project.prototype.setModelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.project_io.Model=} opt_value
 * @param {number=} opt_index
 * @return {!proto.project_io.Model}
 */
proto.project_io.Project.prototype.addModels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.project_io.Model, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.clearModelsList = function() {
  return this.setModelsList([]);
};


/**
 * optional Source source = 5;
 * @return {?proto.project_io.Source}
 */
proto.project_io.Project.prototype.getSource = function() {
  return /** @type{?proto.project_io.Source} */ (
    jspb.Message.getWrapperField(this, proto.project_io.Source, 5));
};


/**
 * @param {?proto.project_io.Source|undefined} value
 * @return {!proto.project_io.Project} returns this
*/
proto.project_io.Project.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.project_io.Project} returns this
 */
proto.project_io.Project.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.project_io.Project.prototype.hasSource = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.project_io.SimMethod = {
  EULER: 0,
  RUNGE_KUTTA_4: 1
};

goog.object.extend(exports, proto.project_io);
